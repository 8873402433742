.c{
    height: 100vh;
    position: relative;
}

.c-bg{
    width: 20px;
    height: 100%;
    position: absolute;
    background-color: #BBF2AB;
    /*background-image: url("../../img/background.png");*/
    /* background-size: cover; */
}

.c-wrapper{
    padding: 50px;
    display: flex;
}

.c-left{
    flex: 1;
}

.c-right{
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-title{
    font-size: 60px;
    width: 80%;
}

.c-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 300;
    width: 70%;
}

.c-icon{
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.c-desc{
    font-weight: 200;
}

form{
    margin-top: 20px;
}

input{
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 2px solid rgb(243, 242, 242);
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

button{
    border: none;
    padding: 15px;
    background-color: #50b081;
    color: white;
    font-weight: 500;
    cursor: pointer;
}

.c-save-button{
    padding: 15px;
}

@media screen and (max-width: 480px){
    .c{
        height: 70vh;
    }

    .c-wrapper{
        flex-direction: column;
    }

    .c-title{
        font-size: 30px;
    }
    
    .c-info-item{
        width: 100%;
        margin: 20px 0px;
    }

    .c-desc{
        display: none;
    }

    form{
        display: none;
    }
}