.a{
    height: 100vh;
    display: flex;
    align-items: center;
}

.a-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}
 .a-card.bg{
     position: absolute;
     top: 50px;
     left: 50px;
     background-color:#50b081;
 }

.a-right{
    flex: 1;
}

.a-card{
    width: 70%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.a-skills{
    align-items: center;
}

.a-skills-img{
    width: 90%;
    height: 100%;
}

.a-sub{
    margin: 20px 20px;
    font-weight: 300;
}

.a-desc{
    font-weight: 300;
    margin: 0px 20px;
}

@media screen and (max-width: 480px){
    .a{
        flex-direction: column;
        text-align: center;
    }

    .a-left{
        width: 100%;
    }

    .a-card{
        height: 40vh;
    }

    .a-card.bg{
        display: none;
    }
    .a-right{
        padding: 20px;
    }
}