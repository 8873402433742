.i{
    display: flex;
    height: 100vh;
}

.i-left{
    flex: 1;
    align-items: center;
    justify-content: center;
}

.i-right{
    flex: 1;
    position: relative;
}

.i-left-wrapper{
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro{
    font-size: 20px;
    font-weight: 300;
}

.i-name{
    font-size: 60px;
}

.i-title{
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper{
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    20%{
        transform: translateY(-50px);
    }
    40%{
        transform: translateY(-100px);
    }
    60%{
        transform: translateY(-150px);
    }
    80%{
        transform: translateY(-200px);
    }
    100%{
        transform: translateY(-250px);
    }
}

.i-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #BBF2AB;
    display: flex;
    align-items: center;
}

.i-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.i-bg{
    clip-path: polygon(100% 0%, 100% 46%, 100% 100%, 38% 100%, 0 53%, 39% 0);
    background-image: url("../../img/background.png");
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 480px){
    .i{
        flex-direction: column;
        height: 50vh;
    }

    .i-left-wrapper{
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .i-title-item{
        display: none;
    }

    .i-description{
        display: none;
    }

    .i-bg{
        display: none;
    }

    .i-img{
        display: none;
    }

}