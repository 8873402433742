.p{
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    transition:  transform .2s;
}

.p-img{
    width: 90%;
    height: 90%;
}

.p-picture-title{
    font-weight: 400;
}

.p:hover{
    transform: scale(1.1);
}

@media screen and (max-width: 480px){
    .p{
        width: 40%;
        height: 20vh;
    }

}